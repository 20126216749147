<div class="row">
  <div class="col-md-12">
    <div class="card">
      <!-- <header class="card-header">Supplier Document SignOff Report</header> -->
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="viewDetails" let-viewDetails let-data="data">
            <obc-file-viewer [urls]="data.fileUrls" [btnTitle]="'View Details'" [btnClass]="'btn btn-info'">
            </obc-file-viewer>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
