<div class="d-flex justify-content-end">
  <button *ngIf="showEditButton()" class="btn btn-primary btn-sm" (click)="onEditProfileButtonClicked()">
    <i class="fas fa-pencil-alt mr-1"></i> Edit
  </button>
  <button *ngIf="isEditMode" class="btn btn-default btn-sm mr-2" (click)="onEditProfileButtonClicked()">
    Cancel
  </button>
  <button *ngIf="isEditMode" class="btn btn-info btn-sm" [disabled]="isSaveButtonDisabled()"
    (click)="onSaveProfileButton()">
    <i class="fas fa-save mr-1"></i> Save Changes
  </button>
</div>

<obc-loader [show]="isInProcess"></obc-loader>
<!-- <div class="row">
      <div class="col-md-12">
        <obc-user-autocomplete (selectedUser)="onUserChange($event)" [placeholder]="'Search User'"
          [filterBy]="UserAutoCompleteFilterByFields.All"></obc-user-autocomplete>
      </div>
    </div>
    <div *ngIf="notFoundProfileMode==true">
      <div class="alert alert-warning">Worker profile not found!</div>
    </div> -->
<form [formGroup]="infoFormGroup" class="{{ isEditMode ? 'editing-mode' : ''}}">
  <div *ngIf="profile">
    <div class="row">
      <div class="col-12 col-md-3 profile-avatar-container">
        <img class="profile-avatar" *ngIf="profile.profileImageUrl" src="{{profile.profileImageUrl}}">
        <div class="profile-avatar profile-avatar-text" *ngIf="!profile.profileImageUrl">
          {{ getProfileAvatarText() }}
        </div>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-12">
            <div class="info-title mb-3">Basic Information</div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>First Name</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ this.profile.firstName ? this.profile.firstName : "N/A" }}
                </ng-container>
                <input *ngIf="isEditMode" type="text" name="firstName" formControlName="firstName" class="form-control"
                  required />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Last Name</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ this.profile.lastName ? this.profile.lastName : "N/A" }}
                </ng-container>
                <input *ngIf="isEditMode" type="text" name="lastName" formControlName="lastName" class="form-control"
                  required />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Mobile</label>
              <div class="profile-item-value">
                {{ profile.mobile | mobile }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Supplier</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ this.profile.companySupplierTitle ?
                  this.profile.companySupplierTitle : "N/A" }}
                </ng-container>
                <obc-company-supplier-selector [control]="infoFormGroup.controls.companySupplier"
                  (selectSupplier)="onSelectSupplier($event)" *ngIf="isEditMode"></obc-company-supplier-selector>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Visitor Type</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ this.profile.visitorTypeTitle ? this.profile.visitorTypeTitle :
                  "N/A" }}
                </ng-container>
                <app-visitor-type-selector [displayAsSingleSelectBox]="true"
                  [selectedVisitorIds]="[infoFormGroup.controls.visitorType.value]" [emptyItemText]="'Not Selected'"
                  (selectedVisitorIdsChange)="selectVisitorType($event)" *ngIf="isEditMode"></app-visitor-type-selector>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Email</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ this.profile.email ? this.profile.email : "N/A" }}
                </ng-container>
                <input *ngIf="isEditMode" type="text" placeholder="Example: john@doe.com" formControlName="email"
                  class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Gender</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  <span *ngIf="isSetGender()">{{ +this.profile.gender | gender }}</span>
                  <span *ngIf="!isSetGender()">N/A</span>
                </ng-container>
                <div *ngIf="isEditMode">
                  <select class="form-control" formControlName="gender">
                    <option [value]="null">Please select a gender</option>
                    <option [value]="Gender.other">Other</option>
                    <option [value]="Gender.female">Female</option>
                    <option [value]="Gender.male">Male</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="profile-item">
              <label>Date Of Birth</label>
              <div class="profile-item-value">
                <ng-container *ngIf="!isEditMode">
                  {{ isSetDOB() ? (this.profile.dateOfBirth | date) : "N/A" }}
                </ng-container>
                <obc-input-date-time-picker *ngIf="isEditMode" formControlName="dateOfBirth"
                  [mode]="DatetimePickerMode.Calender">
                </obc-input-date-time-picker>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="info-title mb-3 mt-2">Emergency Contact Information</div>
          </div>
          <div class="col-12">
            <div class="profile-item">
              <div class="profile-item-value">
                <obc-emergency-contact-viewer *ngIf="!isEditMode" [displayInRow]="true"
                  [labelStyle]="'opacity: 0.6; font-size: 13px; font-weight: normal'" [displayDots]="false"
                  [data]="this.profile.emergencyContact"></obc-emergency-contact-viewer>
                <obc-emergency-contact *ngIf="isEditMode" [displayInRow]="true"
                  [labelStyle]="'opacity: 0.6; font-size: 13px; font-weight: normal'" [displayDots]="false"
                  [required]="false" [jsonData]="this.profile.emergencyContact"
                  (onUpdate)="updateEmergencyContact($event)"></obc-emergency-contact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showPanels">
      <div class="col-12">
        <tabset *ngIf="profile.userId">
          <tab (selectTab)="showTab[0]=1" heading="Check In History">
            <obc-site-activity-report [selectedUserId]="profile.userId" [isReportMode]="true" *ngIf="showTab[0]"
              [siteIdList]="selectedSiteArray">
            </obc-site-activity-report>
          </tab>
          <tab (selectTab)="showTab[1]=1" heading="Inductions">
            <obc-induction-report [selectedUserId]="profile.userId" [isReportMode]="true" *ngIf="showTab[1]"
              [siteIdList]="selectedSiteArray">
            </obc-induction-report>
          </tab>
          <tab (selectTab)="showTab[2]=1" heading="Supplier Document SignOff">
            <obc-supplier-document-signoff-report [selectedUserId]="profile.userId" [isReportMode]="true"
              [siteIdList]="selectedSiteArray" *ngIf="showTab[2]">
            </obc-supplier-document-signoff-report>
          </tab>
          <tab (selectTab)="showTab[3]=1" heading="Site Briefing SignOff">
            <obc-site-briefing-signoff [selectedUserId]="profile.userId" [isReportMode]="true" *ngIf="showTab[3]"
              [siteIdList]="selectedSiteArray">
            </obc-site-briefing-signoff>
          </tab>
          <tab (selectTab)="showTab[4]=1" heading="Cards">
            <obc-worker-profile-card-report [selectedUserId]="profile.userId" [isReportMode]="true" *ngIf="showTab[4]"
              [siteId]="siteId">
            </obc-worker-profile-card-report>
          </tab>
          <tab (selectTab)="showTab[5]=1" heading="History">
            <obc-worker-profile-history [selectedUserId]="profile.userId" [isReportMode]="true" *ngIf="showTab[5]"
              [siteId]="siteId">
            </obc-worker-profile-history>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</form>